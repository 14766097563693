<template>
  <div>
    <BaseBanner :item="[]"/>
      <slot />
  </div>
</template>
<script>

export default {

};
</script>
<style scoped>
  
</style>